import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`April, an asian highschool student living in Wisconsin, USA. She has a hard time in school, she is mad fun of and bullied everyday. She is constantly bullied because she is from Asia. Everybody tells her that she is ugly and she is only good at math and stuff like that. She is especially made fun of by the cheerleading team and the track team. Even though April is an exceptional runner, she is too afraid that she won’t be good enough and the other girls will make fun of her. One day she comes to school and she sees that it is the final day to sign up for track and field. She tells herself that she will try out today. It was after school the tryouts would be. So she would have the whole day to prepare for the tryouts. She wore her track shirt to school that day, she was so ready for the tryouts, she was going to show those mean girls how good she is. However, during math class she saw a group of the girls from the track team snickering and giggling and laughing at her.`}</p>
    <p>{`At that moment she froze, all the bad thoughts rushing through her head, “You will never become a track athlete”. Stick to math and science, you nerd”. She became paralyzed, all she could hear was the thoughts running through her head. They were so loud that she couldn’t even hear the teacher asking her a question. “April, what are you doing, WHAT IS THE ANSWER”! Suddenly she snapped out of it and said, “5.762 Ms Applebaum”. The rest of the period she sat and speculated over, whether she should actually try out, or if the voices in her head were right, that she should stick to doing math and science instead, that she could never become one of them.`}</p>
    <p>{`She had to cool down, remember what her father told her before he left. “ You should rather be hated for who you are, than liked for who you are not”. She realized that she shouldn’t care what they think or anything. She marched out to the track and said that she wanted to try out. Every single girl there started laughing their heads off. They said “ You actually think that you can be on this team you nerd!” April started freezing again, she took a deep breath, and told herself, remember, “You can do this’’. She said confidently “ Who is the fastest one here?” Carla, the head cheerleader raised her hand and said “ Ha, you will never beat me, rather anybody here. April said, “ Come on lets see, 100 meter race, if I beat you I get to be on the team, and you never bully anybody because of their race ever again”. Carla agreed. They both lined up for the race. All of the other girls were booing April. She was so focused she couldn’t even hear them. ‘’ On your marks, set, go!” April starts off slow, she is 1 meter behind Carla, she begins to speed up. Carla taunts April, however, April doesn’t even notice. April starts striding, she gets faster, faster and faster she passes Carla, only 10 meters left, she pushes as hard as she can and she wins the race. In her mind she says, one of my thoughts was true, I will never be one of them, I won’t be a judgemental bully.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      